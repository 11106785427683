<template>
	<slot-simulator v-if="config" :config="config" :uuid="this.$route.params.cuuid"></slot-simulator>
</template>

<script>
	import Grid from "@/components/slot-config-editor/paylines/grid";
	import SlotSimulator from "@/components/slot-simulator";
	import api from "@/api";

	export default {
		name: 'simulator',
		components: {Grid, SlotSimulator},
		data() {
			return {
				config: null
			}
		},
		async mounted() {
			let config = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.config = config.Configuration
		}
	}
</script>
